import React from "react";
import { Link } from "react-router-dom";

export const Contact = (props) => {
  
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="col-md-12">
            <div className="row">
              <div className="section-title">
              </div>
              <div className="row">
                <div className="col-md-3 col-xs-12">
                  <h5>NOVOPAG INSTITUICAO DE PAGAMENTOS LTDA</h5>
                <div className="contact-item">
                  <p> CNPJ {props.data ? props.data.line1.cnpj : "loading"} </p>
                </div>
                <div className="contact-item">
                  <p>{props.data ? props.data.line1.address : "loading"}{" "}</p>
                    <p>{props.data ? props.data.line1.bairro : "loading"}{" "}</p>
                </div>
               
                <div className="contact-item">
                  <p>{props.data ? props.data.line1.city : "loading"}{" "} </p>
                </div>
                <div className="contact-item">
                  <p>CEP {props.data ? props.data.line1.cep : "loading"}{" "} </p>
                </div>
                </div>

                <div className="col-md-2 col-xs-12">
                  <h5>Quem Somos</h5>
                  <div className="contact-item">
                    <a href={props.data ? props.data.about.link1 : '/'}> {props.data ? props.data.about.title1 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <Link to={props.data ? props.data.about.link2 : '/'}> {props.data ? props.data.about.title2 : "loading"} </Link>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.about.link3 : '/'}> {props.data ? props.data.about.title3 : "loading"} </a>
                  </div>
                </div>

                <div className="col-md-2 col-xs-12">
                  <h5>Produtos</h5>
                  <div className="contact-item">
                    <a href={props.data ? props.data.products.link1 : '/'}> {props.data ? props.data.products.title1 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.products.link2 : '/'}> {props.data ? props.data.products.title2 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.products.link3 : '/'}> {props.data ? props.data.products.title3 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.products.link4 : '/'}> {props.data ? props.data.products.title4 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.products.link5 : '/'}> {props.data ? props.data.products.title5 : "loading"} </a>
                  </div>
                </div>

                <div className="col-md-2 col-xs-12">
                  <h5>LGPD</h5>
                  <div className="contact-item">
                    <a href={props.data ? props.data.lgpd.link1 : '/'}> {props.data ? props.data.lgpd.title1 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.lgpd.link2 : '/'}> {props.data ? props.data.lgpd.title2 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.lgpd.link3 : '/'}> {props.data ? props.data.lgpd.title3 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.lgpd.link4 : '/'}> {props.data ? props.data.lgpd.title4 : "loading"} </a>
                  </div>
                  <div className="contact-item">
                  <a href={props.data ? props.data.lgpd.link5 : '/'}> {props.data ? props.data.lgpd.title5 : "loading"} </a>
                  </div>
                </div>

                <div className="col-md-3 col-xs-12">
                  <h5>Fale com a gente</h5>
                  <div className="contact-item">
                    <p>
                      <i className="fa fa-envelope"> </i>{props.data ? props.data.email : "loading"}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-4 lei">
                <p>A NovoPag não realiza qualquer atividade privativa de instituições financeiras, direto a seus clientes. Nos termos da Resolução nº 4.935, de 29 de julho de 2021, do Conselho Monetário Nacional, a NovoPag CNPJ: 50.754.089/0001-50, atua meramente como Parceiro Bancário de instituições financeiras, as quais, na qualidade de agentes bancarizadores devidamente autorizados pelo Banco Central do Brasil, A instituição financeira com a qual atuamos é Delcred Sociedade de Crédito Direto S/A - CNPJ: 38.224.857/0001-68 - Empresa do Sistema Financeiro Nacional nº 435.</p>
              </div> */}
              <div className="col-md-12 lei">
                  <div className="row">
                    <div className="social">
                      <ul>
                        <li>
                          <a href={props.data ? props.data.facebook : "/"}>
                            <i className="fa fa-facebook"></i>
                          </a>
                        </li>
                        <li>
                          <a href={props.data ? props.data.linkedin : "/"}>
                            <i className="fa fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href={props.data ? props.data.insatgram : "/"}>
                            <i className="fa fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href={props.data ? props.data.whatsapp : "/"}>
                            <i className="fa fa-whatsapp"></i>
                          </a>
                        </li>
                      </ul>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2019 NovoPag - Todos os direitos reservados!  {" "}
          </p>
        </div>
      </div>
    </div>
  );
};
